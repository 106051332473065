import React from "react"
import ContentHome from "../components/organisms/content-home/content-home"
import Layout from "../shared/components/organisms/layout/layout"
import SEO from "../shared/components/atoms/seo/seo"
import { graphql } from "gatsby"
import "../shared/styles/index.scss"

const IndexPage = (props) => {
    const { allContentfulExtranetHome } = props.data
    return (
      <Layout>
          <SEO
            title={allContentfulExtranetHome.nodes[0].metaTitulo}
            description={allContentfulExtranetHome.nodes[0].metaDescripcion.metaDescripcion}
          />
          <ContentHome />
      </Layout>
    )
}

export const query = graphql`
    query Meta_Query {
        allContentfulExtranetHome {
            nodes {
                metaTitulo
                metaDescripcion {
                    metaDescripcion
                }
            }
        }
    }
`


export default IndexPage
