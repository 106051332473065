import React, { useEffect } from "react"
import "./section-last-report.scss"
import Button from "../../../shared/components/atoms/button/button"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Link from "gatsby-link"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const SectionLastReport = () => {
    const { allContentfulExtranetHome } = useStaticQuery(
      graphql`
      query LastReport_Query {
         allContentfulExtranetHome {
        nodes {
        imagenReporteIntegrado {
          file {
            url
            details {
            image {
              height
              }
            }
          }
          fluid {
            src
            srcSet
            base64
            aspectRatio
            sizes
          }
          fixed {
          base64
        }
        description
        }
        informacionReporteIntegrado {
          raw
        }
        ctaAccederMicrositio
        ctaDescargaPdf
        ctaRedireccionAccederMicrositio
        ctaUrlDescargaPdf
      }
  
    }
    }
  `
    )
    const {
        ctaAccederMicrositio,
        ctaDescargaPdf,
        ctaRedireccionAccederMicrositio,
        ctaUrlDescargaPdf,
        imagenReporteIntegrado,
        informacionReporteIntegrado
    } = allContentfulExtranetHome.nodes[0]

    const redirect = (url) => {
        window.open(url, "_blank")
    }

    const dataLayerButton = (paramButton) => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "ga_event",
        category: "Home",
        action: "Click :: Reporte Integrado",
        label: paramButton,
        value: "",
      })
    }
    const isActiveButton = false

    return (
      <section className="f-section-lastReport">
          <div className="f-section-lastReport-wrapper">
              <div className="f-section-lastReport-img">
              <div className="f-section-lastReport-img-wrapper">
                  <Img
                    loading="lazy"
                    itemProp="image"
                    className="img-lastReport"
                    fluid={imagenReporteIntegrado.fluid}
                    alt={imagenReporteIntegrado.description}
                  />
              </div>
              </div>
              <div className="f-section-lastReport-content">
                  {documentToReactComponents(JSON.parse(informacionReporteIntegrado.raw))}
                  <div className="f-section-lastReport-content-buttons">
                    {isActiveButton &&
                      <Button title={ctaAccederMicrositio} color="blue" size="large" onClick={() => {
                          redirect(ctaRedireccionAccederMicrositio)
                          dataLayerButton("Acceder al micrositio")
                      }} />
                    }
                      <Button title={ctaDescargaPdf} color="white" size="large" onClick={() => {
                          redirect(ctaUrlDescargaPdf)
                          dataLayerButton("Descargar PDF")
                      }} />
                  </div>
              </div>
          </div>
      </section>
    )
}

export default SectionLastReport
