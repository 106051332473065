import React from "react"
import "./section-our-brands.scss"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"


const SectionOurBrands = () => {


    const { allContentfulExtranetHome } = useStaticQuery(
      graphql`
      query OurBrands_Query{
      allContentfulExtranetHome {
        nodes {
          informacionNuestrasMarcas {
            raw
          }
          nuestrasMarcas {
            redireccionDeLaCategoria
            titulo
            imagenDeLaCategoria {
              file {
                url
                details {
                  image {
                    height
                  }
                }
              }
              fluid {
                src
                srcSet
                base64
                aspectRatio
                sizes
              }
              fixed {
                base64
              }
              description
            }
          descripcionDeLaCategoria {
            raw
          }
          }
        }
      }
    }
  `
    )

    const contentInfo = allContentfulExtranetHome.nodes[0]


    return (
      <div className="f-section-our-brands">

          {documentToReactComponents(JSON.parse(contentInfo.informacionNuestrasMarcas.raw))}
          <div className="f-section-brands-images">
              {contentInfo.nuestrasMarcas.map((item, index) => 
              (
                <div className="f-icon-brand-container" key={index} onClick={() => {
                    window.open(item.redireccionDeLaCategoria, "_blank")
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                      event: "ga_event",
                      category: "Home",
                      action: "Click :: Nuestras Marcas",
                      label: item.titulo,
                      value: "",
                    })
                }}>
                    <div className="f-icon-brand-container-img">
                        <Img
                          loading="lazy"
                          itemProp="image"
                          className="img-brand"
                          fluid={item.imagenDeLaCategoria[0].fluid}
                          alt={item.imagenDeLaCategoria[0].description}
                        />
                    </div>

                    {documentToReactComponents(JSON.parse(item.descripcionDeLaCategoria.raw))}
                  <i className="fas fa-chevron-right"></i>

                </div>
              ))}
          </div>
      </div>
    )
}

export default SectionOurBrands
