import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Slider from "react-slick"
import useMobile from "../../../shared/hooks/useMobile"
import ArrowSlider from "../../../shared/components/atoms/arrows-slider/arrows-slider"
import "./section-main-banner.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { getCountry } from "../../../shared/services/axiosInstance";

const SectionMainBanner = () => {

    const isMobile = useMobile()

    const [country, setCountry] = useState("")

    useEffect(() => {
        getCountry().then((_country) => {
            setCountry(_country)
        })
    }, [])

    const { allContentfulExtranetHome: { nodes } } = useStaticQuery(graphql`
        query BannerQuery {
            allContentfulExtranetHome {
                nodes {
                    tituloPrincipal
                    metaTitulo
                    metaDescripcion {
                        metaDescripcion
                    }
                    bannersPrincipales {
                        id
                        titulo
                        imagenesDelBanner {
                            file {
                                url
                                details {
                                    image {
                                        height
                                    }
                                }
                            }
                            fluid {
                                src
                                srcSet
                                base64
                                aspectRatio
                                sizes
                            }
                        }
                        redireccionDelBanner
                    }
                    bannersPrincipalesEcuador {
                        id
                        titulo
                        imagenesDelBanner {
                            file {
                                url
                                details {
                                    image {
                                        height
                                    }
                                }
                            }
                            fluid {
                                src
                                srcSet
                                base64
                                aspectRatio
                                sizes
                            }
                        }
                        redireccionDelBanner
                    }
                }
            }
        }`)



    const CustomSlide = ({ imagenesDelBanner, redireccionDelBanner, titulo, ...rest }) => {
        return (
            <a className="f-slide-container" href={redireccionDelBanner} onClick={() => {
                window.dataLayer = window.dataLayer || []
                window.dataLayer.push({
                    event: "ga_event",
                    category: "Home",
                    action: "Click :: Banner",
                    label: titulo,
                    value: "",
                })
            }}>
                <img
                    className="f-slide-container__image"
                    src={imagenesDelBanner[isMobile ? 1 : 0].file.url}
                    alt={imagenesDelBanner[isMobile ? 1 : 0].file.description}
                />
            </a>
        )
    }

    const datalayerArrowPrincipalBanner = name => {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            event: "ga_event",
            category: "Home",
            action: "Click :: Banner flechas",
            label: name,
            value: "",
        })
    }

    return (
        <>
            <section className="f-section-main-banner">
                <Slider
                    autoplay
                    speed={2000}
                    autoplaySpeed={3000}
                    dots={true}
                    centerMode={false}
                    dotsClass="f-slick-dots-banner"
                    nextArrow={<ArrowSlider direction="right" banner customOnClick={() => datalayerArrowPrincipalBanner('Siguiente')} />}
                    prevArrow={<ArrowSlider direction="left" banner customOnClick={() => datalayerArrowPrincipalBanner('Anterior')} />}
                >
                    {country === "EC" ? 
                        nodes[0].bannersPrincipalesEcuador.map(
                            ({ id, ...props }) => <CustomSlide key={id} {...props} />
                        )
                        :
                        nodes[0].bannersPrincipales.map(
                            ({ id, ...props }) => <CustomSlide key={id} {...props} />
                        )
                    }
                </Slider>
            </section>
            <div className="f-section-main-title">
                <h1>{nodes[0].tituloPrincipal}</h1>
            </div>

        </>
    )
}


export default SectionMainBanner
