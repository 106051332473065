export const dateFormat = (date, type = "numeric") => {
  const d = new Date(date)
  const ye = new Intl.DateTimeFormat("es", { year: "numeric" }).format(d)
  const da = new Intl.DateTimeFormat("es", { day: "2-digit" }).format(d)

  if (type === "long") {
    const mo = new Intl.DateTimeFormat("es", { month: "long" }).format(d)
    return `${da} de ${mo} ${ye}`
  } else {
    const d = new Date(date)
    const mo = new Intl.DateTimeFormat("es", { month: "numeric" }).format(d)
    return `${da}/${mo}/${ye}`
  }
}
