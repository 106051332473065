import React from 'react'
import SectionMainBanner from "../section-main-banner/section-main-banner"
import SectionLastReport from '../section-last-report/section-last-report'
import SectionOurShops from '../section-our-shops/section-our-shops'
import SectionlastNews from '../section-last-news/section-last-news'
import SectionOurBrands from '../section-our-brands/section-our-brands'
import SectionOurProducts from '../section-our-products/section-our-products'
import SectionContact from '../../../shared/components/organisms/section-contact/section-contact'
import './content-home.scss'



const ContentHome = () => {
    return (
        <>
            <SectionMainBanner />
            <SectionlastNews />
            <SectionOurBrands />
            <SectionOurProducts />
            <SectionLastReport />
            <SectionOurShops />
            <SectionContact />
        </>
    )
}

export default ContentHome
