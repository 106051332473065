import axios from "axios";
import * as apiConstants from "../constants/apiConstants";

let currentExecutionToGetCountry = null

export const axiosInstance = axios.create();
//Get ip Address from jsonip
function getIp() {
  return new Promise((resolve, reject) => {
    axios.get(apiConstants.GET_IP_ADDRESS).then((response) => {
      sessionStorage.setItem("ipAddress", response.data.ip)
      resolve(response.data.ip);
    }).catch((error) => {
      reject(error);
    });
  });
}

export function getCountry() {
  if (!localStorage.getItem("country")) {
      currentExecutionToGetCountry = axios.get(apiConstants.GET_IP_ADDRESS).then((response) => {
        localStorage.setItem("country", response.data.country)
        return response.data.country;
      }).catch((error) => {
        console.error(error);
        return null;
      });
      return currentExecutionToGetCountry;
  }
  return Promise.resolve(localStorage.getItem("country"))
}

axiosInstance.interceptors.request.use(async (config) => {
  if (sessionStorage) {
    const tokenResponse = sessionStorage.getItem("ipAddress") ? sessionStorage.getItem("ipAddress") : await getIp();
    config.headers['Ip-Address'] = tokenResponse;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});
