// Cloudfront
export const SERVER_URL = process.env.ENVIRONMENT === "staging" ? "https://l60xo7vpwj.execute-api.us-east-1.amazonaws.com/stg/extranet/" :
    process.env.ENVIRONMENT === "production" ? "https://2blh4fqkq6.execute-api.us-east-1.amazonaws.com/pdn/extranet/" :
        "https://u79iu4uev0.execute-api.us-east-1.amazonaws.com/dev/extranet/";

//WebToCase Variables
export const WebToCaseUrl = process.env.ENVIRONMENT === 'STG' ? 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8' :
  process.env.ENVIRONMENT === 'PDN' ? 'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8' :
    'https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8';
export const ORGID = process.env.ENVIRONMENT !== 'PDN' ? '00D3j0000001yNQ' : '00D3j0000001yNQ';
export const RECORDTYPE = process.env.ENVIRONMENT !== 'PDN' ? '0123j000000X9zrAAC' : '0123j000000X9zrAAC';

        
//Notification Service
export const WORK_WITH_US = SERVER_URL + "notification/api/v1/work-with-us";
export const DONATION = SERVER_URL + "notification/api/v1/donation";
export const CONTAC_US = SERVER_URL + "notification/api/v1/contact";
export const INVESTOR = SERVER_URL + "notification/api/v1/investor";

//Get ip Address
export const GET_IP_ADDRESS = "https://jsonip.com/";