import React from "react"
import "./section-our-shops.scss"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"


import cuidateEnFamilia from "../../../assets/images/cuidate-en-familia.png"
import familiaInstitucional from "../../../assets/images/familia-institucional.png"
import intimaWear from "../../../assets/images/intima.svg"
import tena from "../../../assets/images/tena.png"

const shops = [
    {
        img: cuidateEnFamilia,
        text: "Ir a Familia Institucional"
    },
    {
        img: familiaInstitucional,
        text: "Ir a Familia Institucional"
    },
    {
        img: intimaWear,
        text: "Ir a Familia Institucional"
    },
    {
        img: tena,
        text: "Ir a Familia Institucional"
    }
]

const SectionOurShops = () => {

    const { allContentfulExtranetHome } = useStaticQuery(
        graphql`
       query OurShops_Query {
        allContentfulExtranetHome {
        nodes {
          informacionNuestrasTiendas {
            raw
          }
          nuestrasTiendas {
            nombreDeLaTienda
            redireccionTienda
            imagenDeLaTienda {
                   fluid {
                src
                srcSet
                base64
                aspectRatio
                sizes
              }
              description
            }
          }
        }
      }
    }   
     `
    )

    const contentInfo = allContentfulExtranetHome.nodes[0]

    return (
        <section className="f-section-ourShops">
            <div className="f-section-ourShops-wrapper">
                <div className="f-section-ourShops-description">
                    {documentToReactComponents(JSON.parse(contentInfo.informacionNuestrasTiendas.raw))}
                </div>
                <div className="f-section-ourShops-shops">
                    {contentInfo.nuestrasTiendas.map((item, index) => {


                        return (
                            <div className="f-section-ourShops-shops-img" key={index}
                                onClick={() => {
                                    window.open(item.redireccionTienda, "_blank")
                                    window.dataLayer = window.dataLayer || []
                                    window.dataLayer.push({
                                      event: "ga_event",
                                      category: "Home",
                                      action:
                                        "Click :: Nuestras Tiendas en Línea",
                                      label: item.nombreDeLaTienda,
                                      value: "",
                                    })
                                }}
                            >
                                <div className="f-section-ourShops-shops-img-container">
                                    <Img
                                        loading="lazy"
                                        itemProp="image"
                                        className="img-shops"
                                        fluid={item.imagenDeLaTienda.fluid}
                                        alt={item.imagenDeLaTienda.description}
                                    />
                                </div>
                                <a>Ir a {item.nombreDeLaTienda}</a>
                                <i className="fas fa-chevron-right"></i>

                            </div>

                        )

                    })}
                </div>
            </div>
        </section>
    )
}

export default SectionOurShops
