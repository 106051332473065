import React from "react"
import "./section-our-products.scss"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import ArrowsSlider from "../../../shared/components/atoms/arrows-slider/arrows-slider"

const SectionOurProducts = () => {

    const { allContentfulExtranetHome } = useStaticQuery(
      graphql`
      query OurProducts_Query {
        allContentfulExtranetHome {
        nodes {
          tituloNuestrosProductos
          nuestrosProductos {
            nombreDeLaCategoria
            imagenDeLaCategoria {
              file {
                url
                details {
                  image {
                    height
                  }
                }
              }
              fluid {
                src
                srcSet
                base64
                aspectRatio
                sizes
              }
              fixed {
                base64
              }
            }
            redireccionDeLaCategoria
          }
        }
      }    
    }
  `
    )

    const contentInfo = allContentfulExtranetHome.nodes[0];

    const settings = {
        dots: false,
        infinite: true,
        nextArrow: <ArrowsSlider direction="right" />,
        prevArrow: <ArrowsSlider direction="left" />,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [
            {
                breakpoint: 1023,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true
                }
            },
            {
                breakpoint: 760,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true
                }
            },

            {
                breakpoint: 480,
                settings: {
                    centerMode: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,

                }
            },
            {
                breakpoint: 375,
                settings: {
                    centerMode: true,

                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }

        ]
    }
    return (
      <section className="f-section-ourProducts">
          <div className="f-section-ourProducts-wrapper">
              <h2>{contentInfo.tituloNuestrosProductos}</h2>
              <div className="f-section-ourProducts-slider">
                  <Slider className="slide" {...settings}>
                      {contentInfo.nuestrosProductos &&
                      contentInfo.nuestrosProductos.map((product, index) => {
                          return (
                            <div className="f-section-ourProducts-slider-product" key={index} >
                                <div className="f-section-ourProducts-slider-product-wrapper">
                                    <div className="f-section-ourProducts-slider-product-img">
                                        <Img
                                          loading="lazy"
                                          itemProp="image"
                                          className="img-product"
                                          fluid={product.imagenDeLaCategoria[0].fluid}
                                          alt={product.nombreDeLaCategoria}
                                        />
                                    </div>


                                    <p className="f-section-ourProducts-slider-product-title">
                                        {product.nombreDeLaCategoria}
                                    </p>


                                </div>
                            </div>

                          )
                      })}


                  </Slider>
              </div>
          </div>
      </section>
    )
}

export default SectionOurProducts
