import React, { useRef, useState, useEffect } from "react"
import Slider from "react-slick"
import useMobile from "../../../shared/hooks/useMobile"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import ArrowSlider from "../../../shared/components/atoms/arrows-slider/arrows-slider"
import Button from "../../../shared/components/atoms/button/button"
import RichText from "../../../shared/components/molecules/rich-text/rich-text"
import { dateFormat } from "../../../shared/utils/dateFormat"
import siteUrl from "../../../shared/utils/siteUrl"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./section-last-news.scss"
import { getCountry } from "../../../shared/services/axiosInstance";

const LastNews = (props) => {

    const slickEl = useRef()
    const [currentSlide, setCurrentSlide] = useState()
    const isMobile = useMobile()

    const [country, setCountry] = useState("")

    useEffect(() => {
        getCountry().then((_country) => {
            setCountry(_country)
        })
    }, [])

    const { allContentfulExtranetArticulosYNoticias: { nodes: news }, allContentfulExtranetHome: { nodes: pageInfo } } = useStaticQuery(graphql`
        query NewsQuery {
            allContentfulExtranetHome {
                nodes {
                    metaTitulo
                    metaDescripcion {
                        metaDescripcion
                    }

                    tituloUltimasNoticias
                    ctaUltimasNoticias
                    ctaRedireccionUltimasNoticias
                }
            }
            allContentfulExtranetArticulosYNoticias (filter: {tipoDeNoticia: {eq: "Noticia"}}, sort: {order: DESC, fields: fechaDePublicacion}) {
                nodes {
                    tituloPrincipal
                    descripcionCortaLink{
                        raw
                    }
                    descripcionCorta {
                        descripcionCorta
                    }
                    imagenPrevisualizacion {
                        title
                        file {
                            url
                            details {
                                image {
                                    height
                                }
                            }
                        }
                        fluid {
                            src
                            srcSet
                            base64
                            aspectRatio
                            sizes
                        }
                    }
                    fechaDePublicacion
                    autor
                    id
                    categoria {
                        titulo
                        nombreDeLaCategoria
                    }
                    tipoDeNoticia
                    slug
                    onlyEcuador
                }
            }
        }
    `)
    
    const filterBySection = (arrayNews = news) => {
        if (props.sectionFilter) {
            let result = arrayNews.filter(data => {
                return data.categoria ? data.categoria[0]?.titulo?.toLowerCase()?.includes(props.sectionFilter.toLowerCase()) : false
            })
            return result.length > 0 ? result : arrayNews
        } else {
            if(country === "EC") {
                return arrayNews     
            }
            else {
                let result = arrayNews.filter(data => {
                    return data.onlyEcuador? data.onlyEcuador === false : true;
                })
                return result.length > 0 ? result : arrayNews
            }
        }
    }

    const datalayerSectionNews = (categoryName, title) =>{
        console.log(categoryName, title)
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: "ga_event",
          category: "Home",
          action: "Click :: Últimas noticias",
          label: categoryName + '::' + title,
          value: "",
        })
    }

    const dataLayerMoreNews = () => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: "ga_event",
        category: "Home",
        action: "Click :: Últimas noticias",
        label: "Ver todas las noticias y artículos.",
        value: "",
      })
    }

    const redirectBtn = (slug) => {
        typeof window !== 'undefined' ? window.location.href= "/noticias/" + slug  : '/'
    }

    return (
        <section className="f-section-last-news">
            <h2 className="f-section-last-news__title">
                {pageInfo[0].tituloUltimasNoticias}
            </h2>
            <div className="f-news-articles-wrapper">
                <Slider
                    ref={slickEl}
                    variableWidth
                    infinite={false}
                    speed={500}
                    afterChange={current => { setCurrentSlide(current) }}
                    slidesToShow={isMobile ? 1 : 3}
                    slidesToScroll={isMobile ? 1 : 2}
                >
                    {
                        filterBySection(news).map((data) => {
                            return (
                                <div className="f-news-article" key={data.id}>
                                    <div className="f-news-article__img">
                                        <Img
                                            loading="lazy"
                                            itemProp="image"
                                            fluid={data.imagenPrevisualizacion && data.imagenPrevisualizacion.fluid}
                                            alt={data.imagenPrevisualizacion && data.imagenPrevisualizacion.title}
                                        />
                                    </div>

                                    <div className="f-news-article__content">
                                        <p className="f-news-article__category">{data.categoria && (data.categoria[0].nombreDeLaCategoria)}</p>
                                        <h2 className="f-news-article__title">{data.tituloPrincipal}</h2>
                                        {data.descripcionCortaLink ? 
                                        <RichText  className="f-news-article__description" jsonInfo={JSON.parse(data.descripcionCortaLink.raw)} /> : 
                                        <p className="f-news-article__description">{data.descripcionCorta.descripcionCorta}</p>}
                                        <Button
                                            title='Leer más'
                                            color='blue'
                                            size="small"
                                            onClick={()=>{redirectBtn(data.slug); datalayerSectionNews(data.categoria[0].nombreDeLaCategoria, data.tituloPrincipal)}}
                                        />
                                        <p className="f-news-article__date">{dateFormat(data.fechaDePublicacion)}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
                {currentSlide > 0 && <ArrowSlider banner direction="left" onClick={() => {
                    slickEl.current.slickPrev()
                }} />}
                <ArrowSlider banner direction="right" onClick={() => {
                    slickEl.current.slickNext()
                }} />
            </div>

            <div className="f-section-last-news__action">
                <a href={`${siteUrl}/${pageInfo[0].ctaRedireccionUltimasNoticias}`} onClick={dataLayerMoreNews}>{pageInfo[0].ctaUltimasNoticias} <i
                    className="fal fa-arrow-right"></i> </a>
            </div>
        </section >
    )
}


export default LastNews
